import React, { useEffect, useState } from "react";
import { imageSrc } from "../../../baseUrl";
import { CallApi } from "../../apiCall";
import "../../Styled/MainOption.scss";
import { getHostName } from "../utils";
import { formatTime } from "../../ChatBot/utils";

const MainOptions = (props) => {
  const [tempData, setTempData] = useState({});
  const [LoadMore, setLoadMore] = useState(8);
  const [LoadOption, setLoadOption] = useState([]);
  const [sortedOptionList, setSortedOptionList] = useState([]);
  const [time, setTime] = useState();
  useEffect(() => {
    setTime(formatTime());
  }, []);

  useEffect(() => {
    const data = props.state.messages.slice(-1)[0].data;

    if (!data) {
      //checking is this is intial message ther or not when data is not there make a initial api call
      const requestData = {
        session_id: localStorage.getItem("chatbot-sessionId")
          ? localStorage.getItem("chatbot-sessionId")
          : "",
        selected_context: "",
        typed_msg: "",
        host: getHostName(),
      };

      CallApi(requestData).then((data) => {
        if (data === "error") {
          props.actionProvider.addErrorMessage();
        } else {
          // console.log("initial", data)
          localStorage.setItem("chatbot-sessionId", data.session_id);
          setTempData(data);
          props.scrollIntoView(true);
          handleLoadLessMoreInitial(data);
        }
      });
    } else {
      //  if data comming from the props then set the state with the data
      setTempData(data);
      handleLoadLessMoreInitial(data);
    }
  }, []);

  const handleLoadLessMoreInitial = (data) => {
    const activeArray = [];
    const deactivetedArray = [];
    data.options.forEach((e) => {
      if (e.leaf_node) {
        activeArray.push(e);
      } else {
        deactivetedArray.push(e);
      }
    });
    const allOptionArray = [...deactivetedArray, ...activeArray];
    // console.log(allOptionArray)
    setSortedOptionList(allOptionArray);
    //if the horizontal view is true then set the oad more option till 8  elements
    if (data.h_view) {
      const loadOptions = [];
      allOptionArray.forEach((e, index) => {
        if (index < LoadMore) {
          loadOptions.push(e);
        }
      });
      if (loadOptions.length) {
        setLoadOption(loadOptions);
      }
      // console.log(loadOptions)
    }
  };

  const handleClick = async (option) => {
    // on click of button for answer make the api call with clicked context id.
    const requestData = {
      session_id: localStorage.getItem("chatbot-sessionId"),
      selected_context: option.uuid,
      typed_msg: "",
      host: getHostName(),
    };

    props.actionProvider.addClientMessage(option.context_name);
    props.actionProvider.handleMainOption(null, requestData);
  };

  const handleLoadMore = () => {
    const option = [];
    sortedOptionList.forEach((e, index) => {
      if (index < LoadMore + 8) {
        option.push(e);
      }
    });
    setLoadOption(option);
    setLoadMore((prevstate) => prevstate + 8);
    document.getElementById("inputCon").focus();
  };

  const handleLoadLess = () => {
    const option = [];
    sortedOptionList.forEach((e, index) => {
      if (index < LoadMore - 8) {
        option.push(e);
      }
    });
    setLoadOption(option);
    setLoadMore((prevstate) => prevstate - 8);
    document.getElementById("inputCon").focus();
  };

  const IPOHandler = (selected_option) => {
    if (
      selected_option === "IPO Allotment Status" ||
      selected_option === "Allotment Status - IPO"
    ) {
      const requestData = {
        session_id: localStorage.getItem("chatbot-sessionId"),
        selected_context: "IPO Allotment Status",
        typed_msg: "",
        host: getHostName(),
      };

      CallApi(requestData).then((data) => {
        // console.log(data)
        window.open(
          "https://linkintime.co.in/initial_offer/public-issues.html",
          "_blank"
        );
      });
    } else if (selected_option === "Allotment Status - Buy-Back") {
      window.open("https://web.linkintime.co.in/Offer/Default.aspx", "_blank");
    } else if (selected_option === "Allotment Status - Rights Issues") {
      window.open(
        "https://web.linkintime.co.in/RIGHTSISSUE/rightsissues-Knowyourapplication.aspx",
        "_blank"
      );
    } else if (selected_option === "Swayam") {
      window.open("https://swayam.linkintime.co.in/Access/Register#", "_blank");
    }
  };

  return (
    <div className={`ButtonContainer`}>
      {/* {console.log(tempData)} */}
      {tempData.options && !tempData.h_view
        ? tempData.options
            .filter((option) => !option.context_name.startsWith("--"))
            .map((option) => (
              <>
                {[
                  "Allotment Status - IPO",
                  "Allotment Status - Buy-Back",
                  "Allotment Status - Rights Issues",
                ].includes(option.context_name) ? (
                  <span
                    // className={`main-btn ${option.context_name === "Allotment Status - IPO" && (getHostName() == 'linkintime' ?  "main-btn-ipo" : "main-btn-disable")} `}
                    className={`main-btn main-btn-ipo`}
                    key={option.uuid}
                    onClick={() => IPOHandler(option.context_name)}
                  >
                    {option.context_name}
                  </span>
                ) : option.context_name === "Allotment Status" ? (
                  <span
                    className={`main-btn main-btn-ipo } `}
                    key={option.uuid}
                    onClick={() => handleClick(option)}
                  >
                    {" "}
                    {option.context_name}
                  </span>
                ) : option.context_name === "Swayam" ? (
                  <span
                    className={`main-btn main-btn-ipo } `}
                    key={option.uuid}
                    onClick={() => IPOHandler(option.context_name)}
                  >
                    {" "}
                    {option.context_name}
                  </span>
                ) : (
                  <span
                    className={`main-btn `}
                    key={option.uuid}
                    onClick={() => handleClick(option)}
                  >
                    {option.context_name.replace("-2023 ", "")}
                  </span>
                )}
              </>
            ))
        : tempData.h_view && (
            <>
              <div
                className={`ButtonContainer-horizontal ${
                  !tempData.bot_message[0] &&
                  "ButtonContainer-horizontal-withoutMsg"
                }`}
              >
                {/* {console.log(LoadOption)} */}
                <span className="ButtonContainer-horizontal-item ButtonContainer-horizontal-head">
                  <span>Related Questions</span>
                </span>
                {LoadOption
                  // .filter(
                  //   (option) => !option.context_name.startsWith("--"))
                  .map((option, el) => {
                    return (
                      <span
                        className={`ButtonContainer-horizontal-item ${
                          !option.leaf_node &&
                          "ButtonContainer-horizontal-item-highlight"
                        }`}
                        key={option.uuid}
                        onClick={() => handleClick(option)}
                      >
                        <span>{option.context_name.replace("-2023 ", "")}</span>
                        <span className="ButtonContainer-horizontal-item-arrow">
                          {">"}
                        </span>
                      </span>
                    );
                  })}
                {tempData.options.length > 8 && (
                  <span className="ButtonContainer-horizontal-item ButtonContainer-horizontal-item-moreless">
                    Load
                    {tempData.options.length > LoadMore ? (
                      <span
                        className="ButtonContainer-horizontal-item-more"
                        key={LoadMore}
                        title="Load More"
                        onClick={handleLoadMore}
                      >
                        {/* <span></span> */}
                        <span className="ButtonContainer-horizontal-item-load">
                          <img
                            className="ButtonContainer-horizontal-item-arrowdown"
                            src={`${imageSrc}/icons/Arrow.svg`}
                            alt="More"
                          />
                          {/* <span className="ButtonContainer-horizontal-item-arrowdown">
                      {">"}
                    </span> */}
                        </span>
                      </span>
                    ) : (
                      <span> </span>
                    )}
                    {LoadMore > 8 && (
                      <span
                        className="ButtonContainer-horizontal-item-less"
                        key={"load-less"}
                        title="Load Less"
                        onClick={handleLoadLess}
                      >
                        {/* <span></span> */}
                        <span className="ButtonContainer-horizontal-item-load">
                          <img
                            className="ButtonContainer-horizontal-item-arrowup"
                            src={`${imageSrc}/icons/Arrow.svg`}
                            alt="Less"
                          />
                          {/* <span className="ButtonContainer-horizontal-item-arrowup">
                      {">"}
                    </span> */}
                        </span>
                      </span>
                    )}
                  </span>
                )}
                {/* {(tempData.options.length <= LoadMore && tempData.options.length > 8) && (
                  <span
                  className="ButtonContainer-horizontal-item"
                  key={LoadMore}
                  onClick={handleLoadLess}
                >
                  <span></span>
                  <span className="ButtonContainer-horizontal-item-load">
                    Load Less
                    <span className="ButtonContainer-horizontal-item-arrowup">
                      {">"}
                    </span>
                  </span>
                </span>
              )} */}
              </div>
              <span className="ButtonContainer-timestamp"> {time} </span>
            </>
          )}
    </div>
  );
};

export default MainOptions;
