import axios from "axios";
import { baseUrl } from "../baseUrl";

const getConfig = () => {
  const token = localStorage.getItem("chatbot_token");
  return {
    headers: {
      "Content-type": "application/json",
      Authorization: `${token}`,
    },
  };
};
export const CallApi = async (requestData) => {
  try {
    const { data } = await axios.post(
      `${baseUrl}/chatbot/chatbot-view/`,
      requestData,
      getConfig()
    );
    return data;
  } catch (e) {
    return "error";

    // throw new Error("Something whent Wrong")
  }
};

export const getToken = async () => {
  const { data } = await axios.get(`${baseUrl}/chatbot/create-token/`);
  const { token } = data;
  token && localStorage.setItem("chatbot_token", token);
};

export const CallApiRatings = async (requestData) => {
  const { data } = await axios.post(
    `${baseUrl}/chatbot/rating/`,
    requestData,
    getConfig()
  );
  return data;
};
export const CallApiFeedback = async (requestData) => {
  const { data } = await axios.post(
    `${baseUrl}/chatbot/feedback/`,
    requestData,
    getConfig()
  );
  return data;
};

export const GetAllOptionForMessageInput = async () => {
  const { data } = await axios.get(
    `${baseUrl}/chatbot/main-menu-options/`,
    getConfig()
  );
  return data;
};

export const GetActiveBannerOption = async () => {
  const { data } = await axios.get(
    `${baseUrl}/chatbot/banner-options/`,
    getConfig()
  );
  return data;
};
export const SendMessageEmailCounter = async (typed_msg) => {
  const { data } = await axios.patch(
    `${baseUrl}/chatbot/mail-selected/`,
    {
      question: typed_msg,
    },
    getConfig()
  );
  return data;
};

export const GetSearchCompney = async (query) => {
  const { data } = await axios.get(
    `${baseUrl}/chatbot/isin/?search=${query}`,
    getConfig()
  );
  return data.results;
};

export const GetTypedCompney = async (requestData) => {
  console.log(requestData);
  const { data } = await axios.post(
    `${baseUrl}/chatbot/isin/`,
    requestData,
    getConfig()
  );
  return data;
};

export const GetBranchAddress = async (requestData) => {
  console.log(requestData);
  const { data } = await axios.get(
    `${baseUrl}/chatbot/branch-address/?${requestData}`,
    getConfig()
  );

  return data;
};
