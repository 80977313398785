import React, { useEffect, useRef, useState } from "react";
import "./../Styled/InputMessageContainer.scss";
import { GetAllOptionForMessageInput, CallApi } from "../apiCall";
import { imageSrc } from "../../baseUrl";
import { getHostName } from "./utils";
import { useContext } from "react";
import Context from "../Context/Context";

// const MenuIconList = [
//   `${imageSrc}/icons/KYCCompliance.svg`,
//   `${imageSrc}/icons/IEPF.svg`,
//   `${imageSrc}/icons/TaxResidencyCertficate.svg`,
//   `${imageSrc}/icons/PublicIssues.svg`,
//   `${imageSrc}/icons/RightIssue.svg`,
//   `${imageSrc}/icons/bondServicing.svg`,
//   `${imageSrc}/icons/DepositoryService.svg`,
//   `${imageSrc}/icons/OffersAndBuyBack.svg`,
//   `${imageSrc}/icons/CSR.svg`,
//   `${imageSrc}/icons/InstaMeet.svg`,
//   `${imageSrc}/icons/TaxExemptionCertificate.svg`,
//   `${imageSrc}/icons/DigitalSign.svg`,
// ];

const InputMessageContainer = (props) => {
  const [expand, setExpand] = useState(false);
  const [optionsList, setOptionsList] = useState(null);
  const [selected, setSelected] = useState("");
  const [menuBubble, setMenuBubble] = useState(true);
  const [inputError, setInputError] = useState(false);
  // console.log(props.actionProvider.stateRef.messages);
  const inputRef = useRef();

  const { showPopup, setShowPopup } = useContext(Context);

  useEffect(() => {
    GetAllOptionForMessageInput().then((data) => setOptionsList(data));

    setMenuBubble(true);
  }, []);

  useEffect(() => {
    // console.log("changes  the state")
    inputRef.current.focus();
    let timmer;
    if (props.actionProvider.stateRef) {
      setExpand(false);
      if (props.actionProvider.stateRef.messages.length > 1) {
        setMenuBubble(false);
      }
      if (
        props.actionProvider.stateRef.messages[
          props.actionProvider.stateRef.messages.length - 1
        ].message !== "I am still here. Do you have any question?"
      ) {
        timmer = setTimeout(() => {
          // console.log("user is inactive for 5 seconds");
          props.actionProvider.handleGenericMessages(
            "I am still here. Do you have any question?"
          );
        }, 5 * 60 * 1000);
      }
    }

    return () => {
      clearTimeout(timmer);
    };
  }, [props]);
  const handle = (e) => {
    // console.log("i have called here")
    e.preventDefault();
    // const value  = props.input.replace(/[^a-zA-Z0-9 ]/g, "")
    const value = props.input.trim();
    if (value.trim()) {
      // props.actionProvider.handleMainOption();
      var regpan = /^([a-zA-Z]){5}([0-9]){4}([a-zA-Z]){1}?$/;
      if (regpan.test(value) && getHostName() === "linkintime") {
        e.preventDefault();
        const requestData = {
          session_id: localStorage.getItem("chatbot-sessionId"),
          selected_context: "IPO Allotment Status",
          typed_msg: "",
          host: getHostName(),
        };
        inputRef.current.value = "";
        inputRef.current.defaultValue = "";
        props.setInputValue("");

        CallApi(requestData).then((data) => {
          // console.log(data)
          window.open(
            "https://linkintime.co.in/initial_offer/public-issues.html",
            "_blank"
          );
        });
      } else {
        props.handleSubmit(e, value);
      }
    }
  };

  const IPOHandler = () => {
    const requestData = {
      session_id: localStorage.getItem("chatbot-sessionId"),
      selected_context: "IPO Allotment Status",
      typed_msg: "",
      host: getHostName(),
    };
    CallApi(requestData).then((data) => {
      // console.log(data)
      window.open(
        "https://linkintime.co.in/initial_offer/public-issues.html",
        "_blank"
      );
    });
  };

  const onClickOptionHandler = (option) => {
    const requestData = {
      session_id: localStorage.getItem("chatbot-sessionId"),
      selected_context: option.uuid,
      typed_msg: "",
      host: getHostName(),
    };
    props.actionProvider.addClientMessage(option.context_name);
    props.actionProvider.handleMainOption(null, requestData);
    setSelected("");
    setExpand(false);
  };

  const data = [
    {
      active: true,
      name: "KYC",
      imgurl: `${imageSrc}/icons/KYCCompliance.svg`,
    },
    { active: true, name: "IEPF", imgurl: `${imageSrc}/icons/IEPF.svg` },
    {
      active: true,
      name: "Public Issues",
      imgurl: `${imageSrc}/icons/PublicIssues.svg`,
    },
    {
      active: true,
      name: "Rights Issues",
      imgurl: `${imageSrc}/icons/RightIssue.svg`,
    },
    {
      active: true,
      name: "Bond Servicing",
      imgurl: `${imageSrc}/icons/bondServicing.svg`,
    },
    {
      active: true,
      name: "Buy-Back",
      imgurl: `${imageSrc}/icons/OffersAndBuyBack.svg`,
    },
    {
      active: true,
      name: "Depository Services",
      imgurl: `${imageSrc}/icons/DepositoryService.svg`,
    },
    {
      active: true,
      name: "Registry",
      imgurl: `${imageSrc}/icons/TaxResidencyCertficate.svg`,
    },
    {
      active: true,
      name: "Insta-Vote",
      imgurl: `${imageSrc}/icons/TaxExemptionCertificate.svg`,
    },
    { active: false, name: "CSR", imgurl: `${imageSrc}/icons/CSR.svg` },
    {
      active: false,
      name: "Insta-Meet",
      imgurl: `${imageSrc}/icons/InstaMeet.svg`,
    },
    {
      active: false,
      name: "Digital Signature",
      imgurl: `${imageSrc}/icons/DigitalSign.svg`,
    },
  ];
  let inputClearErrorTimmer;
  const onChangehandler = (e) => {
    // const inputBox = document.getElementById("inputCon")
    clearTimeout(inputClearErrorTimmer);
    if (
      /^[a-zA-Z0-9!@%^&*()-_+={}'",.?`~ +]+$/.test(e.target.value) ||
      !e.target.value
    ) {
      props.setInputValue(e.target.value);
      // inputBox.setCustomValidity("I Speak Only English")
      if (inputError) {
        setInputError(false);
      }
    } else {
      setInputError(true);
      inputClearErrorTimmer = setTimeout(() => {
        setInputError(false);
      }, 3000);
    }
  };
  return (
    <div
      className={`react-chatbot-kit-chat-input-container ${
        expand && "react-chatbot-kit-chat-input-container-expand"
      }`}
    >
      {showPopup && (
        <div className={`container-popup container-popup-${showPopup.pClass}`}>
          <div
            id={showPopup.id}
            dangerouslySetInnerHTML={{ __html: showPopup.html }}
          />
          {window.navigator.maxTouchPoints || showPopup.containsLink ? (
            <span
              className="container-popup-close"
              onClick={() => setShowPopup(null)}
            >
              +
            </span>
          ) : (
            <></>
          )}
        </div>
      )}
      {/* {console.log(optionsList)} */}
      <form className="react-chatbot-kit-chat-input-form" onSubmit={handle}>
        {menuBubble && (
          <span className="react-chatbot-kit-chat-input-form-bubble">
            <span className="react-chatbot-kit-chat-input-form-bubble-text">
              View All Here
            </span>
            <img
              src={`${imageSrc}/icons/menu-bubble.svg`}
              alt="View All here"
            />
          </span>
        )}
        <div className="react-chatbot-kit-main-menu">
          {/* <CgMenuGridO size={30} color={"#2983C5"} /> */}
          {expand ? (
            selected ? (
              <img
                className="react-chatbot-menuIcon-rotated"
                style={{ width: "35px" }}
                src={`${imageSrc}/icons/DownButton.svg`}
                alt="menu"
                title="Back"
                onClick={() => {
                  setSelected("");
                  inputRef.current.focus();
                }}
              />
            ) : (
              <img
                className="react-chatbot-menuIcon"
                style={{ width: "35px" }}
                src={`${imageSrc}/icons/DownButton.svg`}
                alt="menu"
                title="Minimize Menu"
                onClick={() => {
                  setExpand(!expand);

                  inputRef.current.focus();
                }}
              />
            )
          ) : (
            <img
              className="react-chatbot-menuIcon"
              style={{ width: "35px" }}
              src={`${imageSrc}/icons/MenuIcon.svg`}
              alt="menu"
              title="View All"
              onClick={() => {
                setExpand(!expand);
                setMenuBubble(false);
                inputRef.current.focus();
              }}
            />
          )}
        </div>
        {inputError && (
          <div className="notification-box arrow-bottom">
            Special characters are not allowed.
          </div>
        )}
        <input
          className="react-chatbot-kit-chat-input"
          placeholder="Type your message here"
          value={props.input}
          autoComplete="off"
          ref={inputRef}
          id="inputCon"
          onChange={(e) => onChangehandler(e)}
          required
        />
        {
          getHostName() === "linkintime" && props.input.length < 1 && (
            <img
              className="react-chatbot-kit-chat-ipo"
              src={`${imageSrc}/icons/ipo_bell.svg`}
              alt="ipo"
              title="Check IPO Allotment Status"
              onClick={IPOHandler}
            />
          )
          // <span
          // className="react-chatbot-kit-chat-ipo"
          // title = "Check IPO Allotment Status"
          // onClick={() => window.open("https://linkintime.co.in/initial_offer/public-issues.html", "_blank")}
          // >
          //   IPO
          // </span>}
        }
        <button
          className="react-chatbot-kit-chat-btn-send InputMessageContainer-btn "
          //   style={customButtonStyle}
          //   disabled={!input.trim()}
        >
          <img
            className="react-chatbot-kit-chat-btn-send-icon"
            style={{ width: "35px" }}
            src={`${imageSrc}/icons/ChatIcon.svg`}
            alt="submit"
            title="Submit"
          />
        </button>
      </form>
      {expand && !selected && (
        <div className="expandedMenu">
          {data.map((e, el) => {
            return (
              <>
                {!e.active ? (
                  <span key={e.name} className="expandedMenu-item">
                    {/* <span className="expandedMenu-item-soon">COMING SOON</span> */}
                    <img src={e.imgurl} alt={e.name + " icon"} />
                    <span>{e.name}</span>
                  </span>
                ) : (
                  <span
                    key={e.name}
                    onClick={() => setSelected(e.name)}
                    className="expandedMenu-item expandedMenu-item-active"
                  >
                    <img src={e.imgurl} alt={e.name + " icon"} />
                    <span>{e.name}</span>
                  </span>
                )}
              </>
            );
          })}
        </div>
      )}

      {expand && selected && (
        <div className="expandedOption">
          <div className="expandedOption-container">
            {optionsList ? (
              optionsList[selected].map((option) => {
                return (
                  <div
                    className="expandedOption-container-item"
                    key={option.uuid}
                    onClick={() => onClickOptionHandler(option)}
                  >
                    <span>{option.context_name}</span> <span>{">"}</span>
                  </div>
                );
              })
            ) : (
              <span className="expandedOption-container-error">
                Sorry, Something Wrong here Please Try Again Later.
              </span>
            )}
          </div>
        </div>
      )}
    </div>
  );
};

export default InputMessageContainer;
