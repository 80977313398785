export const formatTime = (d = new Date(), ampm = true) => {
  let hour = d.getHours();

  if (ampm) {
    var a = hour >= 12 ? "PM" : "AM";
    hour = hour % 12;
    hour = hour ? hour : 12; // the hour '0' should be '12'
  }

  hour = checkDigit(hour);
  var minute = checkDigit(d.getMinutes());
  var second = checkDigit(d.getSeconds());

  // https://stackoverflow.com/questions/1408289/how-can-i-do-string-interpolation-in-javascript
  return ampm ? `${hour}:${minute} ${a}` : `${hour}:${minute}`;
};
function checkDigit(t) {
  return t < 10 ? `0${t}` : t;
}

export const getHostName = () => {
  const host = window.location.hostname;
  if (
    host.includes("localhost") ||
    host.includes("127.0.0.1") ||
    host.includes("link-intime-test-server") ||
    host.includes("idia.bluetickconsultants")
  ) {
    return "linkintime";
  } else if (
    host.includes("linkintime.co") ||
    host.includes("https://linkintime.co.in") ||
    host.includes("linkintime")
  ) {
    return "linkintime";
  } else if (
    host.includes("tcplindia.co.in") ||
    host.includes("https://tcplindia.co.in/") ||
    host.includes("tcplindia")
  ) {
    return "tcplindia";
  } else if (
    host.includes("www.unisec.in") ||
    host.includes("unisec") ||
    host.includes("unisec.in")
  ) {
    return "unisec";
  } else if (
    host.includes("http://www.skdc-consultants.com/") ||
    host.includes("skdc-consultants") ||
    host.includes("skdc-consultants.com")
  ) {
    return "skdc-consultants";
  } else {
    let temp = host.split(".co")[0];
    if (temp.includes("www.")) {
      return temp.replace("www.", "");
    }
    return temp;
  }
};

export const ScrollToBottom = () => {
  setTimeout(() => {
    const container = document.querySelector(
      ".react-chatbot-kit-chat-message-container"
    );
    container.scrollTop = container.scrollHeight;
  }, 150);
};
